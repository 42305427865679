<template>
  <div class="wrapper-center-vertical home">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 text-center">
          <img src="../assets/images/itfa-logo.png" alt="Itfa" class="img-fluid mb-3 logo-itfa">
          <h1>{{$t('itfa_abr')}}</h1>
          <p>{{$t('selectPlatform')}}</p>
          <div class="row">
            <div class="col-4 mb-3">
              <b-button class="btn-platform" block variant="outline-primary" :to="{ name: 'FrontHome', params: { lang: lang, console: 'psn' } }">
                <img src="../assets/images/psn-icon.png" alt="Consola PSN">
                <span>PSN</span>
              </b-button>
            </div>
            <div class="col-4 mb-3">
              <b-button class="btn-platform" block variant="outline-primary" :to="{ name: 'FrontHome', params: { lang: lang, console: 'xbox' } }">
                <img src="../assets/images/xbox-icon.png" alt="Consola xbox">
                <span>XBOX</span>
              </b-button>
            </div>
            <div class="col-4 mb-3">
              <b-button class="btn-platform" block variant="outline-primary" :to="{ name: 'FrontHome', params: { lang: lang, console: 'pc' } }">
                <img src="../assets/images/pc-icon.png" alt="Consola pc">
                <span>PC</span>
              </b-button>
            </div>
            <div class="col-12 mb-3" v-if="!isAuthenticated">
              <span>-</span>
            </div>
          </div>
          <div class="row" v-if="!isAuthenticated">
            <div class="col-12 text-center wrapper-btns">
              <b-button variant="primary" size="lg" :to="{ name: 'Login', params: { lang: lang } }">{{$t('login')}}</b-button>
              <btns-auth-social />
            </div>
          </div>
          <div class="row mt-3" v-else>
            <div class="col-12 text-center wrapper-btns">
              <b-button variant="primary" size="lg" :to="{ name: 'Dashboard', params: { lang: lang, console: console } }">Dashboard</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import BtnsAuthSocial from './Auth/BtnsAuth'

export default {
  components: {
    BtnsAuthSocial
  },
  computed: {
    ...mapGetters([
      'console',
      'lang',
      'isAuthenticated'
    ])
  },
  mounted () {
    document.title = `${this.$t('home')} - ITFA`
  }
}
</script>
